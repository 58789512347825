import Api from '@/Services/Api'
import FetchOrchestrator from "@/Services/FetchOrchestrator/FetchOrchestrator";
import Request from "@/Services/FetchOrchestrator/Request";

export default {

    async getPoseSdp() {
        let request = new Request("/planete-online/alertes/pose-sdp", "get")
        await FetchOrchestrator.fetch(request)

        return new Promise((resolve, reject) => {
            request.onRelease(function (response) {
                resolve((!response || response.status == 404) ? 404 : response.data)
            })
            request.onFail(reject)
        });
    },
    async getInterim() {
        let request = new Request("/planete-online/alertes/interim", "get")
        await FetchOrchestrator.fetch(request)

        return new Promise((resolve, reject) => {
            request.onRelease(function (response) {
                resolve((!response || response.status == 404) ? 404 : response.data)
            })
            request.onFail(reject)
        });
    },
    async getCommandes() {
        let request = new Request("/planete-online/alertes/commandes", "get")
        await FetchOrchestrator.fetch(request)

        return new Promise((resolve, reject) => {
            request.onRelease(function (response) {
                resolve((!response || response.status == 404) ? 404 : response.data)
            })
            request.onFail(reject)
        });
    },
    async getMatriculePaie() {
        let request = new Request("/planete-online/alertes/matricule-paie", "get")
        await FetchOrchestrator.fetch(request)

        return new Promise((resolve, reject) => {
            request.onRelease(function (response) {
                resolve((!response || response.status == 404) ? 404 : response.data)
            })
            request.onFail(reject)
        });
    },
    async getRapport() {
        let request = new Request("/planete-online/alertes/rapport", "get")
        await FetchOrchestrator.fetch(request)

        return new Promise((resolve, reject) => {
            request.onRelease(function (response) {
                resolve((!response || response.status == 404) ? 404 : response.data)
            })
            request.onFail(reject)
        });
    },
    async getListContrats() {
        let request = new Request("/planete-online/alertes/list-contrats", "get")
        await FetchOrchestrator.fetch(request)

        return new Promise((resolve, reject) => {
            request.onRelease(function (response) {
                resolve((!response || response.status == 404) ? 404 : response.data)
            })
            request.onFail(reject)
        });
    },
    async getListCandidats() {
        let request = new Request("/planete-online/alertes/list-candidats", "get")
        await FetchOrchestrator.fetch(request)

        return new Promise((resolve, reject) => {

            request.onRelease(function (response) {
                resolve((!response || response.status == 404) ? 404 : response.data)
            })
            request.onFail(reject)
        });
    },
    async getRights() {
        let response = await Api().get('/planete-online/alertes/rights')
        return response.data
    }


}
