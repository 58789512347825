<template>
  <v-app-bar app>
    <router-link :to="{name:'Home'}" class="d-flex flex-row align-center">
      <img src="/image/Logo-Client.gif" height="40" class="mx-4" alt="Client logo">
    </router-link>
    <div class="mx-4">
      <clock @click.native="$router.push({name: 'Agenda'})"></clock>
    </div>
    <v-spacer></v-spacer>
    <v-spacer></v-spacer>

    <!-- icon de statistique -->
    <storage-indicator v-if="rightStorage != 0" ></storage-indicator>

    <user-guide></user-guide>


    <v-divider vertical class="mx-4"></v-divider>

    <module-video v-if="hasVideoRights"></module-video>

    <v-btn class="pa-0" dense @click="toggleDark" icon>
      <v-icon class="ma-0">mdi-brightness-6</v-icon>
    </v-btn>

    <languages></languages>

    <notification-center></notification-center>

  </v-app-bar>
</template>

<script>
import NotificationsService from "@/Services/NotificationsService";
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";
import Vuex from "vuex";

export default {
  name: "TopAppBar",
  components: {
    UserGuide: () => import("@/Components/Commons/Interface/TopAppBar/UserGuide"),
    Clock: () => import("@/Components/Commons/Interface/TopAppBar/Clock"),
    Languages: () => import("@/Components/Commons/Interface/TopAppBar/Languages"),
    ModuleVideo: () => import("@/Components/Commons/Interface/TopAppBar/ModuleVideo"),
    NotificationCenter: () => import("@/Components/Commons/Interface/Notifications/NotificationCenter"),
    StorageIndicator: () => import("@/Components/Commons/Interface/TopAppBar/StorageIndicator"),
  },
  data() {
    return {
      presentation: true,
      storageRight: null,
    }
  },
  computed: {
    ...Vuex.mapState(["modules"]),
    logoPlaneteOnline() {
      return this.$vuetify.theme.dark ? require("@/Assets/Images/Logo/logo-planete-online-darkmode.png") : require("@/Assets/Images/Logo/logo-planete-online.png")
    },
    hasVideoRights() {
      return this.modules.filter(module => module.icon == "video").length
    },
    rightStorage() {
      return this.storageRight
    }
  },
  methods: {
    toggleDark() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$store.commit("switchDarkMode", {theme: this.$vuetify.theme.dark})
      let st = JSON.parse(JSON.stringify(this.$_.pick(this.$store.state, ["preferences"])))
          st.preferences.background = ""
      PlaneteOnlineService.postInterface(st)
    }
  },
  async mounted() {
    const rights = await NotificationsService.getRights()
    this.storageRight = rights.rights.statistique
  }
}
</script>

<style scoped>
  /*.topappbar {*/
  /*  position: absolute;*/
  /*  z-index: 1000000;*/
  /*}*/
</style>